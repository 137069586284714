<template>
  <div>
    <SystemHead page-type="sign" />
    <div class="form-signup mb-5 mx-auto">
      <div class="text-center">
        <img :src="logo" class="logo" alt="" />
      </div>
      <h4>{{ $t("sign.create_account") }}</h4>
      <el-form :model="formData" :rules="rules" ref="registerForm">
        <div class="row">
          <div class="col-md-6">
            <el-form-item :label="$t('sign.email') + '：'" prop="email">
              <el-input v-model="formData.email"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6">
            <el-form-item :label="$t('sign.username') + '：'" prop="username">
              <el-input v-model="formData.username"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <el-form-item :label="$t('sign.password') + '：'" prop="password">
              <el-input v-model="formData.password"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-6">
            <el-form-item
              :label="$t('sign.re_enter_pass') + '：'"
              prop="repassword"
            >
              <el-input v-model="formData.repassword"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <el-form-item
              :label="$t('sign.veri_code') + '：'"
              prop="invitationcode"
            >
              <el-input v-model="formData.invitationcode"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <el-form-item prop="agreeDeclaration">
              <el-checkbox v-model="formData.agreeDeclaration"></el-checkbox>
              <span class="fs12 ml5">{{ $t("sign.sign_tetx1") }}</span>
              <router-link to="/" class="fs12 ml5" target="_blank">{{
                $t("sign.statement")
              }}</router-link>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <button
        type="button"
        class="btn btn-primary"
        @click="submitForm('registerForm')"
      >
        {{ $t("sign.sign_tetx2") }}
      </button>
      <div class="mt-3">
        <router-link to="/">{{ $t("sign.sign_text3") }}</router-link>
      </div>
    </div>
    <footer class="footer mt-auto py-2 fixed-bottom text-center">
      <div class="container">
        <small class="d-block text-muted"
          >@2019 Brainlabel.org. All rights reserved.</small
        >
      </div>
    </footer>
  </div>
</template>

<script>
import logo from "@/assets/logo.png";
import { login } from "../../api/api";
import SystemHead from "../../layouts/systemHead";
import { MessageBox, Loading } from "element-ui";

export default {
  components: {
    SystemHead,
  },
  data() {
    return {
      logo: logo,
      formData: {
        email: "",
        username: "",
        password: "",
        repassword: "",
        invitationcode: "",
        agreeDeclaration: "",
      },
    };
  },
  computed: {
    rules() {
      return {
        email: [
          {
            required: true,
            message: this.$t("login_failure"),
            trigger: "change",
          },
        ],
        username: [
          {
            required: true,
            message: this.$t("login_failure"),
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("login_failure"),
            trigger: "change",
          },
        ],
        repassword: [
          {
            required: true,
            message: this.$t("login_failure"),
            trigger: "change",
          },
        ],
        invitationcode: [
          {
            required: true,
            message: this.$t("login_failure"),
            trigger: "change",
          },
        ],
        agreeDeclaration: [
          {
            required: true,
            message: this.$t("login_failure"),
            trigger: "change",
          },
        ],
      };
    },
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.handleRegister();
        } else {
          return false;
        }
      });
    },
    async handleRegister() {
      let submitData = {
        email: this.formData.email,
        username: this.formData.username,
        password: this.formData.password,
        repassword: this.formData.repassword,
        invitationcode: this.formData.invitationcode,
        agreeDeclaration: this.formData.agreeDeclaration,
        type: "register",
      };
      let _self = this;
      try {
        let res = await login(submitData);
        if (res.status === 200) {
          this.$helper.successNotify(
            res.message,
            function () {
              _self.$router.push("/");
            },
            1000
          );
        } else {
          this.$helper.errNotify(res.message);
        }
      } catch (error) {
        console.log(error.message);
        this.$helper.errNotify(error.message);
      }
    },
  },
};
</script>

<style lang="less">
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-signin {
  width: 100%;
  max-width: 360px;
  padding: 15px;
  margin: auto;
  .checkbox {
    font-weight: 400;
    .form-control {
      position: relative;
      box-sizing: border-box;
      height: auto;
      padding: 10px;
      font-size: 16px;
      &:focus {
        z-index: 2;
      }
    }
  }
  input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.logo {
  max-height: 80px;
  height: 80px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-signup {
  margin-top: 80px;
  max-width: 700px;
  label {
    margin-bottom: 0;
  }
}
</style>
